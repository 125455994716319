import React, {useMemo, useState} from 'react';
import {classes} from './ProductLineItemOverride.st.css';
import {useControllerProps} from '../../../ControllerContext';
import {ProductLineItem} from '@wix/wixstores-client-common-components/dist/es/src/ProductLineItem/ProductLineItem';
import {ICartItem} from '../../../../../../types/app.types';
import {
  getHref,
  getIsOutOfStock,
  getIsQuantityErrorNeeded,
  getMaxItemQuantity,
  hasFreeText,
  hasOptions,
} from '../../../../../../domain/utils/itemUtils';
import {CartItemDataHook} from '../CartItem/CartItem';
import {useTranslation} from '@wix/yoshi-flow-editor';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {debounce} from '../../../../../../domain/lib/debounce';

export function CommonProductLineItem({item}: {item: ICartItem}) {
  const {t} = useTranslation();
  const {cartStore, navigationStore} = useControllerProps();
  const {removeItemFromCart, shouldPresentTooltipWithoutNumber, updateItemQuantity} = useControllerProps().cartStore;

  const [hasQuantityError, setHasQuantityError] = useState<boolean>(false);
  const debouncedUpdateItemQuantity = useMemo(() => debounce(updateItemQuantity), [updateItemQuantity]);

  const href = getHref(cartStore, item);
  const onImageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    navigationStore.navigateToProduct(item.product.urlPart);
  };

  const allOptions = [];
  hasOptions(item) && allOptions.push(...item.optionsSelectionsValues);
  hasFreeText(item) && allOptions.push(...item.freeText);

  const isItemOutOfStock = getIsOutOfStock(item);

  const handleQuantityChange = (value: string) => {
    const isQuantityErrorNeeded = getIsQuantityErrorNeeded(item, +value);
    if (isQuantityErrorNeeded) {
      triggerQuantityErrorIfNeeded();
      return;
    }
    void debouncedUpdateItemQuantity(item.cartItemId, +value, item.product.id);
  };

  const triggerQuantityErrorIfNeeded = () => {
    setHasQuantityError(true);
    setTimeout(() => {
      setHasQuantityError(false);
    }, 2e3);
  };

  return (
    <div data-hook={CartItemDataHook.ProductLineItem}>
      <ProductLineItem>
        <ProductLineItem.Image product={item.product} imageRef={href} onImageClick={onImageClick} />
        <ProductLineItem.Name name={item.product.name} />
        <ProductLineItem.Price
          formattedComparePrice={item.convertedPrices.formattedComparePrice}
          formattedPrice={item.convertedPrices.formattedPrice}
          className={classes.myPrice}
        />
        <ProductLineItem.TotalPrice formattedTotalPrice={item.convertedPrices.formattedTotalPrice} />
        <ProductLineItem.Options itemId={item.cartItemId} options={allOptions} className={classes.myOptions} />
        <ProductLineItem.Remove
          ariaLabel={t('cart.sr_remove_item_from_cart', {item_name: item.product.name})}
          onClick={() => removeItemFromCart(item)}
          className={classes.myRemove}
        />
        {isItemOutOfStock && (
          <ProductLineItem.OutOfStock message={t('cart.errors.item_out_of_stock')} icon={<Error />} />
        )}
        <ProductLineItem.Quantity
          quantity={item.quantity}
          hasQuantityError={hasQuantityError}
          inventoryQuantity={item.inventoryQuantity}
          incrementAriaLabel={t('cart.sr.addQty')}
          decrementAriaLabel={t('cart.sr.removeQty')}
          inputAriaLabel={t('cart.sr.chooseQty')}
          quantityAriaLabel={t('cart.sr.quantity')}
          handleChange={handleQuantityChange}
          tooltipContent={
            shouldPresentTooltipWithoutNumber
              ? t('cart.errors.item_quantity_exceed_inventory_no_number')
              : t('cart.errors.item_quantity_exceed_inventory', {
                  itemsAmount: getMaxItemQuantity(item),
                } as object)
          }
          className={classes.myQuantity}
        />
      </ProductLineItem>
    </div>
  );
}
